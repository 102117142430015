/// Titles/Headings
///////////////////////////

// Page Title
.page-title {
    font-family: $page-title-ff;
    font-size: $page-title-fs;
    font-weight: $page-title-fw;
}

@media (min-width: 992px) {
    .page-title {
        font-size: $page-title-fs-lg;
    }
}

.page-subtitle {
  font-family: $page-subtitle-ff;
  font-size: $page-subtitle-fs;
  font-weight: $page-subtitle-fw;
}

@media (min-width: 992px) {
  .page-subtitle {
      font-size: $page-subtitle-fs-lg;
  }
}
