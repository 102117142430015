body {
  min-width: $min-body-width;

  &.nav-shown {
    overflow: hidden;
  }
}

.nk {
  &-body {
    outline: none;
    // background: linear-gradient(135deg, rgba(228,228,228,1) 0%, rgba(245,245,245,1) 70%, rgba(228,228,228,1) 100%);
    background: rgb(230,230,230);
    background: linear-gradient(135deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(255,255,255,1) 65%, rgba(230,230,230,1) 78%, rgba(230,230,230,1) 100%);
  }

  &-app {
    &-root {
      outline: none;
    }
  }

  &-main {
    position: relative;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &-content {
    padding: $content-gap-y 4px $content-gap-y 4px;

    &-fs {
      min-height: calc(100vh - (#{$header-height} + #{$header-height}));
      display: flex;
      align-items: center;
    }
  }

  &-progress-bar {
    position: fixed;
    width: 100%;
    margin-top: -$content-gap-y;
    margin-left: -4px;
    margin-right: -4px;
  }
}

@include media-breakpoint-up(sm) {
  .nk {
    &-content {
      padding: $content-gap-y $content-gap-x-sm ($content-gap-y + 10) $content-gap-x-sm;

      &-fluid {
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    &-progress-bar {
      margin-top: -($content-gap-y);
      margin-left: -$content-gap-x-sm;
      margin-right: -$content-gap-x-sm;
    }
  }
}

@include media-breakpoint-up(lg) {
  .nk {
    &-content {
      &-lg {
        padding-top: ($content-gap-y * 1.5);
        padding-bottom: ($content-gap-y * 1.5);
      }

      &-fluid {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

@media (min-width: 1660px) {
  .nk-content {
    &-lg {
      padding-top: ($content-gap-y * 2.25);
      padding-bottom: ($content-gap-y * 2.25);
    }

    &-fluid {
      padding-left: 44px;
      padding-right: 44px;
    }
  }
}

// Content
.nk-content-body {
  flex-grow: 1;
}

.nk-wrap-nosidebar {
  .nk-content {
    padding: 0 !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
