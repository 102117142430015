
.map-issues {
  $self: &;

  color: $color-issue;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0.5rem;
    color: inherit;
  }

  &__title {
    font-size: 1.0625rem;
    font-weight: $fw-700;
    line-height: 1.1;
  }

  &__toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    color: inherit;

    &__svg {
      display: block;
      width: 16px;
      margin-left: 0.375rem;
      margin-top: -2px;
    }
  }

  .svg-enable {
    display: block;
  }

  .svg-disable {
    display: none;
  }

  &__counter {
    font-size: 1.125rem;
    font-weight: $fw-700;
    line-height: 1;
  }

  &__empty {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: $fw-600;
    line-height: 1.1;
    color: $light-500;
    max-height: 80px;

    span {
      display: block;
      padding: 0.875rem 0.5rem 0.625rem 1rem;
    }
  }

  &__items {
    display: block;
    background-color: $white;
    border: 1px solid;
    border-radius: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    max-height: 600px;
    transition: all 300ms ease;

    &.has-active {
      color: #777;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    line-height: 1;
    padding: 0.625rem 0.125rem;
    margin-bottom: 0.25rem;
    color: inherit;
    cursor: pointer;

    &:first-child {
      margin-top: 0.25rem;
    }

    // &:hover {
    //   color: $base-500;
    // }

    &.active {
      color: $white;
      background: rgb(185,51,45);
      background: linear-gradient(90deg, rgba(185,51,45,1) 0%, rgba(236,106,90,1) 100%);
    }

    &__counter {
      font-size: 1.25rem;
      font-weight: $fw-700;
      line-height: 1;
      text-align: right;
      padding-right: 0.25rem;
      flex: 0 0 15%;
    }

    &__icon {
      flex: 0 0 32px;

      &__svg {
        display: block;
        width: 20px;
        margin: 0 auto;
      }
    }

    &__label {
      flex-grow: 1;
      font-size: 0.8125rem;
      font-weight: $fw-600;
      line-height: 1;
      text-transform: uppercase;
      text-align: left;
      padding: 0 0.25rem 0 0.75rem;
    }

    &__info {
      flex: 0 0 30px;

      &__svg {
        display: block;
        width: 16px;
        margin: 0 auto;
      }
    }
  }

  &.map-recommendations {
    color: #347ec1;

    #{ $self }__item {
      &.active {
        color: #fff;
        background: #347ec1;
      }
    }
  }

  &.disable {
    color: #777;

    .svg-disable {
      display: block;
    }

    .svg-enable {
      display: none;
    }

    #{ $self }__items {
      max-height: 0;
      overflow: hidden;
      border-bottom-color: transparent;
    }

    #{ $self }__empty {
      max-height: 0;
      overflow: hidden;
    }
  }
}
