
.gm-ui-hover-effect {
  top: 0 !important;
  right: -4px !important;

  span {
    width: 24px !important;
    height: 24px !important;
    margin: 0 !important;
  }
}
