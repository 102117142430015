.nk {
  &-footer {
    margin-top: auto;
    background: $footer-bg-color;
    border-top: 1px solid $footer-border-bottom;
    padding: $footer-main-gap-y $footer-main-gap-x;

    &.is-dark:not([class*=bg-]) {
      background: $footer-bg-dark-color;
    }

    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &-copyright {
      color: $base-light;

      a {
        color: inherit;

        &:hover {
          color: $accent-color;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .nk {
    &-footer {
      padding: $footer-main-gap-y $footer-main-gap-x-sm;
    }
  }
}

@include media-breakpoint-up(lg) {
  .nk {
    &-footer {
      &-fluid {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

@media (min-width:1660px) {
  .nk {
    &-footer {
      &-fluid {
        padding-left: 44px;
        padding-right: 44px;
      }
    }
  }
}
