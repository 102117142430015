.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  cursor: grabbing;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.dragula{
    &-container{
        > *:not(:last-child) {
            margin-bottom: 0.75rem;
        }
        > * {
            cursor: move;
            cursor: grab;
            touch-action: none;
        }
        &.has-handle > *{
            cursor: initial;
        }
    }
    &-handle{
        display: flex;
        .handle{
            position: relative;
            font-size: 1.125rem;
            cursor: move;
            margin: 0 .875rem;
            &:after{
                position: absolute;
                top:0;
                right: 0;
                bottom: 0;
                font-family: $nk-dashlite-font;
                content: $ni-move;
                font-size: 1rem;
            }
        }
    } 
}


