.cell-has-link {
  // font-size: 1rem;
  font-weight: $fw-medium;
  color: $black;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}
