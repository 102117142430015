
.equipment-data {
  $self: &;

  position: relative;
  height: 100%;

  &__close {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 50%;
    padding: 0;
    margin-top: -58px;
    transform: rotate(180deg);
    z-index: 10;

    &__icon {
      display: block;
      width: 35px;
      height: 95px;
    }
  }

  &__tabs {
    padding: 30px 0 20px 0;

    .nav-tabs {
      border-color: $light-500;
      background-color: $light-200;
      border-top: 1px solid;
      padding: 0 2rem;

      .nav-link {
        text-transform: uppercase;
        font-weight: $fw-normal;
        color: $light-600;
        padding: 0 0.5rem;
        height: 32px;

        &::after {
          background: #55802E;
          bottom: -1px;
        }

        &.active {
          font-weight: $fw-bold;
          color: #55802E;
        }
      }

      .nav-item {
        padding: 0 0.5rem;
      }
    }

    .tab-content {
      height: calc(100vh - 61px); // 30px + 31px -> padding y altura de tab
      overflow: auto;
      // padding: 10px 10px 0 10px;
      padding: 0;
      margin: 0;
      // background-color: #F6F6F6;
      background-color: #FFFFFF;
    }
  }

  &__container {
    display: flex;
    gap: 25px;
    // gap: 5%;
    // padding: 70px 80px 40px 40px;
  }

  &__group {
    background-color: $white;
    border: 1px solid $light-400;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    &--disable {
      #{$self}__title {
        color: $light-500;
      }

      #{$self}__content {
        opacity: 0.65;
        filter: grayscale(1);

        .text-primary {
          color: $light-700 !important;
        }
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.0625rem;
    line-height: 1.2;
    font-weight: $fw-700;
    background-color: $light-200;
    color: $light-900;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }

  &__content {
    padding: 0.5rem 1.5rem 1rem 1.5rem;

    &.scrollable {
      overflow: auto;
      height: calc(100% - 35px); // El alto de la cabecera de la caja
    }

    p {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;

      strong {
        font-weight: $fw-600;
        color: $light-900;
      }
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: $fw-300;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
  }
}

.equipment-info-general {
  display: block;
  // padding: 25px 6px 0 6px;
  padding: 0;

  &__grid {
    // height: calc(100vh - 51px); // 20px + 31px -> padding y altura de tab
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-template-rows: 68% 32%;
    // grid-template-rows: minmax(550px, 68%) minmax(230px, 32%);
    // grid-template-rows: 550px 230px;
    padding: 14px 14px;
    background-color: #F6F6F6;
  }

  &__gap {
    // flex: 1 0 33.3333%;
    // padding: 0 14px 28px 14px;
    padding: 12px 12px;

    &__group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 28px;
    }
  }

  @media (min-width: 1360px) {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.table-equipment-data-details {
  width: 100%;

  &__thead {
    &__label {
      line-height: 1.2;
      font-size: 0.625rem;
      font-weight: $fw-normal;
      padding-right: 1rem;
      vertical-align: bottom;
      border-bottom: 2px solid $primary;
    }

    &__primary {
      line-height: 1.1;
      font-size: 0.8125rem;
      font-weight: $fw-normal;
      color: $white;
      background-color: $primary;
      text-transform: uppercase;
      text-align: center;
      border-color: $primary;
      border-bottom-width: 2px;
    }

    &__secondary {
      line-height: 1.1;
      font-size: 0.8125rem;
      font-weight: $fw-normal;
      color: $white;
      background-color: $light-500;
      text-transform: uppercase;
      text-align: center;
      border-color: $light-500;
      border-bottom-width: 2px;
      border-left-color: $white;
    }

    & > tr > th {
      padding: 3px 4px 2px 4px;
    }

    & > tr > th {
      border-left: 2px solid $white;
    }

    & > tr > th:first-child {
      border-left: none;
    }
  }

  &__tbody {
    &__label {
      line-height: 1.2;
      font-size: 0.875rem;
      font-weight: $fw-bold;
      text-transform: uppercase;
      vertical-align: bottom;
    }

    &__primary {
      line-height: 1.2;
      font-size: 1.25rem;
      font-weight: $fw-bold;
      color: $primary;
      text-align: center;
    }

    &__secondary {
      line-height: 1.2;
      font-size: 1.25rem;
      font-weight: $fw-bold;
      color: $light-500;
      text-align: center;
    }

    & > tr > td {
      padding: 8px 0 3px 0;
    }

    & > tr {
      border-bottom: 1px dashed $primary;
    }

    & > tr:first-child > td {
      padding-top: 9px;
    }
  }
}

// .bar-chart-canvas {
//   margin: 0 auto;
// }
