
// From: https://gwfh.mranftl.com/fonts

// @font-face {
//     font-family: 'Nunito';
//     src: url('../../../fonts/Nunito-Regular.eot');
//     src: local('Nunito Regular'), local('Nunito-Regular'),
//         url('../../../fonts/Nunito-Regular.eot#iefix') format('embedded-opentype'),
//         url('../../../fonts/Nunito-Regular.woff2') format('woff2'),
//         url('../../../fonts/Nunito-Regular.woff') format('woff'),
//         url('../../../fonts/Nunito-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Nunito';
//     src: url('../../../fonts/Nunito-Bold.eot');
//     src: local('Nunito Bold'), local('Nunito-Bold'),
//         url('../../../fonts/Nunito-Bold.eot#iefix') format('embedded-opentype'),
//         url('../../../fonts/Nunito-Bold.woff2') format('woff2'),
//         url('../../../fonts/Nunito-Bold.woff') format('woff'),
//         url('../../../fonts/Nunito-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

/* manrope-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src: url('../../../fonts/manrope-v15-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: url('../../../fonts/manrope-v15-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/manrope-v15-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('../../../fonts/manrope-v15-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: url('../../../fonts/manrope-v15-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('../../../fonts/manrope-v15-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: url('../../../fonts/manrope-v15-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
