
.map-filter {
  $self: &;

  color: #363636;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0.5rem;
    color: inherit;
    border-bottom: 1px solid;
  }

  &__title {
    font-size: 1.0625rem;
    font-weight: $fw-700;
    line-height: 1.1;
  }

  &__toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    color: inherit;

    &__svg {
      display: block;
      width: 16px;
      margin-left: 0.375rem;
      margin-top: -2px;
    }
  }

  .svg-enable {
    display: block;
  }

  .svg-disable {
    display: none;
  }

  &__body {
    padding-left: 1rem;
    max-height: 600px;
    transition: all 300ms ease;
  }

  &__resume {
    margin-left: -1rem;
    margin-bottom: 0.5rem;

    &__total {
      padding: 0.5rem 0.125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        font-size: 0.75rem;

        span {
          font-size: 0.875rem;
          font-weight: $fw-700;
        }
      }
    }

    &__clear {
      text-transform: uppercase;
      font-size: 0.8125rem;
      line-height: 1;
      padding: 0.125rem 0.5rem;
      border: none;
      background-color: transparent;

      &:hover {
        color: #000;
      }
    }
  }

  &__label {
    display: inline-block;
    color: #999;
    background-color: $white;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.375rem 0.625rem 0.375rem 0.75rem;
    margin: 0.1875rem 0.25rem;
    border-radius: 1rem;
    border: 1px solid #aaa;
    cursor: pointer;
    color: #000;
    border-color: #000;
    user-select: none;

    &:hover {
      color: #000;
      border-color: #000;
    }

    span {
      padding-right: 0.5rem;
    }

    svg {
      margin-top: -2px;
    }
  }

  &.disable {
    color: #777;

    #{ $self }__body {
      max-height: 0;
      overflow: hidden;
      border-bottom-color: transparent;
    }
  }
}

.map-filter-item {

  &__header {
    border-bottom: 1px solid #666;
    color: #666;

    &.show {
      .filter-open {
        display: block;
      }

      .filter-close {
        display: none;
      }
    }
  }

  .filter-open {
    display: none;
  }

  .filter-close {
    display: block;
  }

  &__toggle {
    padding: 0.5rem 0.5rem 0.5rem 0.125rem;
    border: none;
    background-color: transparent;
    position: relative;
    color: #666;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__svg {
      display: block;
      width: 14px;
      height: 14px;
    }
  }

  &__title {
    font-size: 0.8125rem;
    font-weight: $fw-600;
    line-height: 1.1;
    text-transform: uppercase;
  }

  &__options {
    margin: 0 0 0.25rem 0;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 300ms ease;

    &__container {
      margin: 0.5rem 0 0.25rem 0;
    }

    &.show {
      max-height: 300px;
    }
  }

  &__label {
    display: inline-block;
    color: #999;
    background-color: $white;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    margin: 0.1875rem 0.25rem;
    border-radius: 1rem;
    border: 1px solid #aaa;
    cursor: pointer;

    &:hover {
      color: #000;
      border-color: #000;
    }

    span {
      padding-right: 0.5rem;
    }

    svg {
      visibility: hidden;
    }

    &--active {
      color: #000;
      border-color: #000;

      span {
        padding-right: 0.5rem;
      }

      svg {
        visibility: visible;
      }
    }
  }
}
