
.code-debug {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 1.5em;
  margin: 2rem 0 1rem 0;
}

code.debug {
  white-space: pre;
  display: block;
  // font-size: 0.75em;
  line-height: 1.6;

  p, pre {
    margin-bottom: 0.25rem;
  }
}

