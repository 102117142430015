.nk{
    &-split{
        position: relative;
        display: flex;
        &-page{
            min-height: 100vh;
        }
        &-content{
            position: relative;
            flex-shrink: 0;
            min-height: 100%;
            &-md{
                @include media-breakpoint-up(md){
                    width: 642px;
                }
            }
        }
        &-stretch{
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

@include media-breakpoint-down(lg){
    .nk-split-stretch{
        &.toggle-break-lg{
            width: 460px;
        }
    }
}

@each $name, $value in $container-max-widths {
    @include media-breakpoint-down($name){
        .nk-split-#{$name} {
            flex-wrap: wrap;
            > *{
                width: 100% !important;
            }
        }
    }
}