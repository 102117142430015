/// Main Sidebar
/////////////////////////////
.nk {
  &-sidebar {
    position: absolute;
    background: $sidebar-bg-color;
    height: 100%;
    min-height: 100vh;
    top: 0;
    border-right: 1px solid $sidebar-border-color;
    left: 0;
    transform: translateX(-100%);
    z-index: 1021;
    width: $sidebar-width-normal;
    transition: transform $sidebar-transition-duration $sidebar-transition-name, width $sidebar-transition-duration $sidebar-transition-name;
    box-shadow: 1px 0px 4px 0px #aaa;

    + .nk-wrap {
      transition: padding $sidebar-transition-duration $sidebar-transition-name;

      > .nk-header-fixed {
        transition: left $sidebar-transition-duration $sidebar-transition-name;
      }
    }

    &.is-light {
      background: $sidebar-bg-light-color;
      border-right-color: $sidebar-border-light-color;
    }

    @if($dark_option==true) {
      &.is-dark {
        background: $sidebar-bg-dark-color;
        border-right-color: $sidebar-border-dark-color;
      }
    }

    @if($dark_theme_option==true) {
      &.is-theme {
        background: $sidebar-bg-theme-color;
        border-right-color: $sidebar-border-theme-color;
      }
    }

    &-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $sidebar-overlay;
      z-index: 900;
      animation: overlay-fade-in .4s ease 1;
    }

    &-element {
      overflow: hidden;
      width: 100%;
      max-height: 100%;
    }

    &.nk-sidebar-active {
      transform: translateX(0);
    }

    &-fixed {
      position: fixed;
      max-height: 100vh;
    }

    &-bottom-border {
      border-radius: 3px;
      margin: 0 10px;
      height: 3px;
      background: rgb(70,124,78);
      background: linear-gradient(90deg, rgba(70,124,78,1) 0%, rgba(148,193,31,1) 100%);
    }

    &-head {
      display: flex;
      align-items: center;
      padding: $header-main-gap-y $sidebar-gap-x;
      min-width: 100%;
      width: $sidebar-width-normal;
      height: $header-height;
      // border-bottom: 1px solid $border-light;

      @if($dark_option==true) {
        .is-dark & {
          border-color: $sidebar-border-dark-color;
        }
      }

      @if($dark_theme_option==true) {
        .is-theme & {
          border-color: $sidebar-border-theme-color;
        }
      }
    }

    &-brand {
      position: relative;
      flex-shrink: 0;
    }

    &-logo {
      transition: opacity .3s ease;

      .logo-img:not(:first-child) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-widget {
      padding-left: $sidebar-gap-x;
      padding-right: $sidebar-gap-x;
      padding-top: 2.5rem;

      &-full {
        padding-left: 0;
        padding-right: 0;

        >* {
          padding-left: $sidebar-gap-x;
          padding-right: $sidebar-gap-x;
        }

        .toggle-expand-content {
          padding-left: 0;
          padding-right: 0;

          >* {
            padding-left: $sidebar-gap-x;
            padding-right: $sidebar-gap-x;
          }
        }
      }

      &-sap {
        border-bottom: 1px solid $border-light;
      }
    }

    &-footer {
      background-color: $sidebar-bg-color;
      margin-top: auto;
      padding: 0.5rem 0 0.5rem;
      height: $sidebar-footer-height;
      overflow: visible;

      .info-text {
        display: block;
        font-size: 0.75rem;
        line-height: 1.2;
      }

      .is-light & {
        background: $sidebar-bg-light-color;
      }

      @if($dark_option==true) {
        .is-dark & {
          background-color: $sidebar-bg-dark-color;
        }
      }

      @if($dark_theme_option==true) {
        .is-theme & {
          background-color: $sidebar-bg-theme-color;
        }
      }
    }

    &-body {
      position: relative;

      .simplebar-content {
        padding-bottom: .25rem !important;
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }

      .simplebar-scroll-content {
        min-height: 100%;
        width: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$header-height} - #{$sidebar-footer-height});

      .nk-sidebar-menu {
        padding-top: 1.5rem;
        padding-bottom: 3rem;

        &[data-simplebar] {
          height: 100% !important;
        }
      }

      &[data-simplebar] {
        >div {
          width: 100%
        }
      }
    }

    &-profile {
      &-fixed {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: $sidebar-bg-color;

        @if($dark_option==true) {
          .is-dark & {
            background: $sidebar-bg-dark-color;
          }
        }

        @if($dark_theme_option==true) {
          .is-theme & {
            background: $sidebar-bg-theme-color;
          }
        }
      }
    }

    @if($dark_option==true or $dark_theme_option==true) {

      &.is-theme,
      &.is-dark {
        .user-balance-alt {
          color: $accent-light;
        }
      }
    }
  }

  &-profile {
    &-fixed {
      position: fixed;
      bottom: 0;
    }

    &-toggle {
      display: block;
      border-bottom: 1px solid $border-light;
    }

    &-content {
      background: $white;
      border-bottom: 1px solid $border-light;

      .nk-profile-menu & {
        background: $lighter;
      }
    }

    &-menu {
      >* {
        padding-left: $sidebar-gap-x;
        padding-right: $sidebar-gap-x;
      }

      .toggle-expand-content {
        padding-left: 0;
        padding-right: 0;

        >* {
          padding-left: $sidebar-gap-x;
          padding-right: $sidebar-gap-x;
        }
      }
    }
  }
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include media-breakpoint-down(lg) {
  .nk {
    &-sidebar {
      &-mobile {
        width: $sidebar-width-normal !important;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .nk-sidebar-content .nk-sidebar-menu {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }

  .nk-sidebar {
    overflow: hidden;
    transform: translateX(0);
    width: $sidebar-width-normal;

    + .nk-wrap,
    &-overlay+.nk-wrap {
      padding-left: $sidebar-width-normal;

      > .nk-header-fixed {
        left: $sidebar-width-normal;
      }
    }

    &.is-compact {
      &:not(.has-hover) {
        width: $sidebar-width-compact;

        .logo-img {
          opacity: 0;
          transition: opacity .2s linear;
        }

        .user-card {
          position: relative;
          left: -5px;
        }

        .user-info {
          opacity: 0;
        }

        .nk-sidebar-profile {
          .dropdown-menu {
            opacity: 0;
          }
        }
      }

      + .nk-wrap {
        padding-left: $sidebar-width-compact;

        > .nk-header-fixed {
          left: $sidebar-width-compact;
        }
      }
    }

    &-overlay {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .nk {
    &-sidebar {
      transform: translateX(0);
    }
  }
}
