
.lb-marker {
  border: 2px solid transparent;
  border-radius: 50%;
  background-color: transparent;
  font-family: $base-font-family;

  &__image {
    &__source {}
  }

  &__issue {
    position: absolute;
    top: -0.625rem;
    right: -0.625rem;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    border: 2px solid $color-issue;
    background-color: rgba($white, 0.85);
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 700;
    color: $color-issue;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
      width: 1.375rem;
      height: 1.375rem;
    }
  }

  .highlight & {
    border-color: $accent-color;
    background-color: #fff;
  }

  .highlight.has-recommendation & {
    border-color: $color-recommendation;
  }

  .highlight.has-issue & {
    border-color: $color-issue;
  }

  &__recommendation {
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    border: 2px solid $color-recommendation;
    background-color: rgba($white, 0.85);
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 700;
    color: $color-recommendation;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}

.lb-cluster {
  font-family: $base-font-family;

  &__content {
    font-size: 1.875rem;
    font-weight: 700;
    border-radius: 50%;
    color: $white;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0,102,51);
    background: linear-gradient(60deg, rgba(0,102,51,0.8) 0%, rgba(149,193,31,1) 100%);
  }

  .lb-marker__issue {
    top: -20%;
    right: -20%;
  }

  .lb_marker__recommendation {
    top: -20%;
    left: -20%;
  }
}

.disable-recommendations .lb-marker__recommendation {
  display: none;
}

.disable-issues .lb-marker__issue {
  display: none;
}
