
.equipment-table {
  max-height: 390px;
  overflow-y: auto;
  border: 1px solid $light-400;
  border-radius: 1rem;

  /* Estilos de la barra de scroll para Firefox */
  scrollbar-width: thin; /* Ancho reducido */
  scrollbar-color: #a0a0a0 transparent; /* Color de la barra y del fondo */

  /* Estilos de la barra de scroll para navegadores basados en WebKit */
  &::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de scroll vertical */
    height: 8px; /* Altura de la barra de scroll horizontal (si aplica) */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo de la pista del scroll */
    // border-radius: 4px; /* Bordes redondeados para la pista */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Color de la barra de desplazamiento */
    border-radius: 4px; /* Bordes redondeados para la barra */
    border: 2px solid #e0e0e0; /* Espacio alrededor de la barra de desplazamiento */
  }

  table {
    table-layout: fixed;
    margin-bottom: 0;
  }

  thead {
    position: sticky;
    top: 0; /* Mantiene la cabecera fija en la parte superior */
    background-color: #f1f1f1; /* Establece un color de fondo para que la cabecera sea visible al hacer scroll */
    z-index: 1; /* Asegura que la cabecera esté por encima del contenido */

    tr {
      background-color: $light-200;
    }

    th {
      text-transform: uppercase;
      padding-top: 0.625rem;
      padding-bottom: 0.525rem;
      border-left: 1px solid $light-400;

      &:first-child {
        border-left: none;
      }
    }
  }

  tbody {
    td {
      font-size: 0.8125rem;
      border-left: 1px solid $light-400;
      overflow: hidden;

      &:first-child {
        border-left: none;
      }
    }

    tr:nth-child(even) {
      td {
        background-color: $light-100;
      }
    }

    tr:hover {
      td {
        background-color: $light-300;
      }
    }
  }

  .word-wrap {
    max-width: 100%; /* Limita el ancho para evitar que el texto salga del contenedor */
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
