

.map-loaded {
  .map-type-control {
    visibility: visible;
  }
}

.nk-content-map {
  height: 100vh;
}

.status-map {
  // border: 1px solid red;
  // margin-bottom: 40px;
  position: relative;
  z-index: 100;
  padding-left: 320px;
  transition: padding 450ms ease;

  &__container {
    display: block;
    height: 100vh;
  }

  &__brand {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 102;

    &__image {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  iframe + div {
    border: none!important;
  }
}

.map-container {
  position: relative;

  &.is-compact-map-sidebar {
    .map-sidebar {
      transform: translateX(-100%);
      box-shadow: none;
    }

    .status-map {
      padding-left: 0;
    }
  }

  &.is-show-equipment-info {
    .equipment-info {
      transform: translateX(0);
    }

    .equipment-card__info__open {
      transform: translateX(638px);
      transition: transform 0.6s 0.3s;
    }

    .equipment-card {
      box-shadow: 1px 0px 4px 0px #aaa;
    }

    .map-sidebar {
      transition: box-shadow 0s ease 450ms;
      box-shadow: none;
    }
  }

  &.is-show-equipment-info-extend {
    .equipment-info-extend {
      transform: translateX(0);
    }
  }

  // &.is-compact-map-sidebar.is-show-equipment-info {
  //   .status-map {
  //     padding-left: 0;
  //   }
  // }
}

.is-sidebar-compact .map-container.is-show-equipment-info .equipment-card__info__open {
  transform: translateX(372px);
}


.map-sidebar {
  position: fixed;
  z-index: 105;
  width: 320px;
  height: 100vh;
  background: rgb(230,230,230);
  background: linear-gradient(135deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(255,255,255,1) 65%, rgba(230,230,230,1) 78%, rgba(230,230,230,1) 100%);
  box-shadow: 1px 0px 4px 0px #aaa;
  transform: translateX(0);
  transition: transform 450ms ease, width 450ms ease, box-shadow 0s ease 0ms;
}

.map-sidebar-separator {
  width: 320px;
  height: auto;
  position: absolute;
  margin-top: -10px;
  z-index: 100;
}

.equipment-info {
  position: fixed;
  z-index: 105;
  width: 320px;
  height: 100vh;
  background: rgb(230,230,230);
  background: linear-gradient(135deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(255,255,255,1) 65%, rgba(230,230,230,1) 78%, rgba(230,230,230,1) 100%);
  box-shadow: none;
  transform: translateX(-100%);
  // transform: translateX(-363px); // 320px + 43px
  transition: transform 450ms ease, width 450ms ease, box-shadow 0s ease 450ms;
}

.map-sidebar-header {
  display: block;
  height: 188px;
  background-image: url('/assets/img/status-map/bg-map-sidebar-header-photo.jpg');
  background-size: cover;
  position: relative;
  overflow: hidden;

  &.stats-map {
    background-image: url('/assets/img/status-map/bg-map-sidebar-header.jpg');
  }

  &__background-image {
    position: absolute;
    top: -36px;
    width: 185px;
    height: auto;
    right: -28px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 1rem;
  }

  &__header {
    padding: 0.75rem 1rem 0.25rem 1rem;
  }

  &__logo {
    width: 150px;
    height: auto;
  }

  &__title {
    font-size: 0.9375rem;
    font-weight: $fw-700;
    line-height: 1;
    padding: 0.375rem 0;
  }

  &__body {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  &__stats {
    padding-top: 0.625rem;
    color: $base-500;

    &:first-child {
      padding-top: 0;
    }

    &__quantity {
      line-height: 1.1;
      font-size: 1.4375rem;
      font-weight: $fw-700;
      margin-bottom: 0.125rem;
    }

    &__label {
      font-size: 0.6375rem;
      font-weight: $fw-400;
      line-height: 1.1;
      text-transform: uppercase;
    }
  }
}

.map-sidebar-content {
  display: block;
  height: calc(100% - 188px) !important;
}


.equipment-info-extend {
  position: fixed;
  left: $sidebar-width-compact + 320px;
  z-index: 104;
  width: calc(100% - ($sidebar-width-compact + 320px));
  height: 100vh;
  transform: translateX(-100vw);
  // background: rgb(230,230,230);
  // background: linear-gradient(135deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(255,255,255,1) 65%, rgba(230,230,230,1) 78%, rgba(230,230,230,1) 100%);
  transition: transform 450ms ease;
  background-color: rgba(230, 230, 230, 0.75);
}

