.dual-listbox {
  display: flex;
  flex-direction: column;

  .dual-listbox__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .dual-listbox__search {
    @extend .form-control;
    max-width: 300px;
    &--hidden{
      display: none;
    }
  }

  .dual-listbox__available,
  .dual-listbox__selected {
    border: 1px solid $border-light;
    height: 300px;
    overflow-y: auto;
    padding: 0;
    width: 300px;
    margin-top: 0;
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }

  .dual-listbox__buttons {
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px 10px 0;
  }

  .dual-listbox__button {
      margin-bottom: 5px;
      justify-content: center;
      text-transform: capitalize;
      @extend .btn;
      @extend .btn-light;
  }

  .dual-listbox__title {
    font-size: 1rem;
    font-weight: $fw-medium;
    padding: .5rem 1rem;
    border-left: 1px solid $border-light;
    border-right: 1px solid $border-light;
    border-top: 1px solid $border-light;
    margin-top: 1rem;
    -webkit-margin-before: 1rem;
    border-radius: $input-border-radius $input-border-radius 0 0;
  }

  .dual-listbox__item {
    display: block;
    padding: .5rem 1rem;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-bottom: 1px solid $border-light;
    transition: background-color 0.2s ease;
    &:hover{
      @extend .bg-lighter;
    }
    &:active,
    &.dual-listbox__item--selected {
      @extend .bg-primary-dim;
    }
  }
}

@include media-breakpoint-between(sm,lg){
  .dual-listbox{
    .dual-listbox__container{
      flex-wrap: nowrap;
      > div:not(.dual-listbox__buttons){
        width: 100%;
      }
    }
    .dual-listbox__available, .dual-listbox__selected{
      width: auto;
    }
    .dual-listbox__buttons{
      margin: 10px auto;
    }
  } 
  .dual-listbox__buttons{
    flex-shrink: 0;
  }
}

@include media-breakpoint-down(sm){
  .dual-listbox{
    .dual-listbox__container{
      > div:not(.dual-listbox__buttons){
        width: 100%;
      }
    }
    .dual-listbox__available, .dual-listbox__selected{
      width: 100%;
    }
    .dual-listbox__buttons{
      margin: 10px auto 0;
    }
  }
}