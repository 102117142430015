
.map-type-control {
  visibility: hidden;
  background-color: #fff;
  color: #666;

  border: 0px;
  border-radius: 2px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  width: 40px;
  height: 40px;

  cursor: pointer;
  text-align: center;

  position: absolute;
  bottom: 23px;
  right: 62px;

  &:hover {
    .map-type-control__source {
      color: #000;
    }
  }

  &.search-imei {
    right: 114px;
  }

  &__layer {
    position: absolute;
    top: 0;
  }

  &__control {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    background: $white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    width: 0;
    overflow: hidden;
    transition: width 0.1s;
  }

  &__input {
    border: 1px solid transparent;
    background-color: $white;
    width: 180px;
    padding: 8px 15px;
    font-size: 1rem;
    line-height: 1em;

    &::placeholder {
      color: #ccc;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.active {
    .map-type-control__control {
      width: 180px;
      transition: width 0.2s;
    }

    .map-type-control__input {
      border-right: 1px solid $gray-400;
    }
  }

  &.empty-search {
    .map-type-control__input {
      background-color: rgba($red, 0.3);
      border-color: $red;
    }
  }
}

.equipment {
  right: 114px;
  width: auto;
}


