
.equipment-card {
  position: relative;
  height: 100%;

  &__close {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 8px;
    top: 4px;
    padding: 0.25rem;

    &__icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  &__content {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
    height: 100%;
  }

  &__header {
    margin-bottom: 1rem;
    padding-top: 2.25rem;
    height: 250px;
  }

  &__body {
    height: calc(100vh - 266px) !important; // 250px + 1rem
  }

  &__equipment {
    display: flex;

    &__title {
      color: $black;
      font-size: 1.4375rem;
      line-height: 1.2;
      font-weight: $fw-800;
      position: relative;

      sup {
        display: none;
        font-weight: $fw-600;
        font-size: 0.625em;
        position: absolute;
        top: -10px;
        left: 0;
        color: $light-400;
      }
    }

    &__photo {
      flex: 0 0 50%;
      background-position: right center;
      background-size: 240px;
      background-repeat: no-repeat;

      img {
        display: none;
        width: 250px;
        height: auto;
        max-width: 230px;
      }
    }

    &__data {
      flex: 0 0 50%;
      padding-top: 1rem;
    }

    &__statuses {
      padding: 1.25rem 0 1rem 0;
    }

    &__status {
      display: flex;
      align-items: center;
      margin-bottom: 0.125rem;

      &__icon {
        display: block;
        width: 30px;
      }

      &__label {
        color: $light-900;
        padding-left: 0.5rem;
        font-size: 1.125rem;
        font-weight: $fw-500;
      }
    }
  }

  &__issues {
    padding: 0 1rem 0 2rem;
  }

  &__recommendations {
    padding: 0 1rem 0 2rem;
  }

  &__info {
    padding: 1rem 2rem 1rem 1.25rem;

    &__title {
      font-size: 1.0625rem;
      font-weight: $fw-700;
      line-height: 1.1;
      padding: 0.625rem 0.75rem 0.375rem 0.75rem;
      border-bottom: 1px solid;
      margin-bottom: 1rem;
    }

    &__item {
      padding: 0.125rem 0 0.125rem 0.75rem;
      font-size: 0.8125rem;
      line-height: 1.2;

      strong {
        font-weight: $fw-700;
      }

      span {
        color: $light-500;
      }
    }

    &__actions {
      padding: 1.5rem 0 0.5rem 0;
    }

    &__button {
      display: block;
      margin: 0 auto;
      background-color: $white;
      border: 1px solid;
      border-radius: 1rem;
      font-size: 0.75rem;
      font-weight: $fw-500;
      padding: 0.1875rem 1.5rem;

      &:hover {
        background-color: $accent-color;
        color: $white;
      }
    }

    &__open {
      position: fixed;
      top: 50%;
      left: 0;
      margin-top: -58px;
      padding: 0;
      border: none;
      background-color: transparent;
      z-index: 101;
      transform: translateX(-100%);
      transition: transform 0.3s;
    }
  }

  &__issue {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;

    &__content {
      flex-grow: 1;
    }

    &__icon {
      color: $color-issue;
      flex: 0 0 34px;

      &__svg {
        display: block;
        width: 22px;
      }
    }

    &__label {
      padding-top: 3px;
      font-size: 1rem;
      font-weight: $fw-bold;
      line-height: 1.2;
      color: $color-issue;
      text-transform: uppercase;
    }

    &__date {
      font-size: 0.8125rem;
      color: $light-500;

      strong {
        color: $light-700;
        font-weight: $fw-600;
      }
    }
  }

  &__recommendation {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;

    &__content {
      flex-grow: 1;
    }

    &__icon {
      color: $color-recommendation;
      flex: 0 0 34px;

      &__svg {
        display: block;
        width: 22px;
      }
    }

    &__label {
      padding-top: 3px;
      font-size: 1rem;
      font-weight: $fw-bold;
      line-height: 1.2;
      color: $color-recommendation;
      text-transform: uppercase;
    }

    &__date {
      font-size: 0.8125rem;
      color: $light-500;

      strong {
        color: $light-700;
        font-weight: $fw-600;
      }
    }
  }
}

