
.border{
    &-transparent{
        border-color: transparent;
    }
    &-dashed{
        border-style: dashed !important;
    }
    &-dotted{
        border-style: dotted !important;
    }
}