
.implode-comma {
  &::before {
    content: ", ";
    margin-right: 0.25rem;
  }

  &:first-child {
    &::before {
      content: "";
      margin-right: 0;
    }
  }
}
